import '../sass/project.scss';

(function ($) {
    "use strict";

    // Get csrftoken
    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie != '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = jQuery.trim(cookies[i]);
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) == (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    $(document).ready(() => {
        if ($('#partners').length) {
            $('#partners').slick({
                dots: true,
                autoplay: true,
                speed: 1500,
                arrows: false,
                autoplaySpeed: 1900,
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 5,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            dots: false,
                            infinite: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            dots: false,
                            infinite: true,
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]
            });
        }

        $('#subscribe-form').submit(function (event) {
            var csrftoken = getCookie('csrftoken');
            $.ajax({
                type: 'post',
                url: $(this).attr('action'),
                data: {
                    'email': $(this).find('input[name=email]').val(),
                    'csrfmiddlewaretoken': csrftoken,
                },
                success: function (msg) {
                    $('#subscribe-form').append("<h4 class='text-center'>" + msg['msg'] + "</h4>");
                    $('#subscribe-form .remove-js').remove();
                },
            });
            return false;
        });
    })

}(jQuery));

const header = document.getElementById("Header");
if (header) {
    const nav_1 = document.getElementById("nav_1");
    const white_logo = document.querySelector('#logo_blanco');
    const header_height = header.offsetHeight;

    window.addEventListener('scroll', function () {
        const scrollpos = window.scrollY;
        if (scrollpos >= header_height) {
            header.classList.add("bg-gray-900");
            nav_1.classList.add("hidden");
            white_logo.classList.remove("hidden");
        } else {
            header.classList.remove("bg-gray-900");
            nav_1.classList.remove("hidden");
            white_logo.classList.add("hidden");
        }
    });
}

/*————————————————————————————————————————————————————*\
    ●❱ ANIMATIONS
\*————————————————————————————————————————————————————*/

let slideUp = (target, duration = 500) => {
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + "ms";
    target.style.boxSizing = "border-box";
    target.style.height = target.offsetHeight + "px";
    target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = "none";
        target.style.removeProperty("height");
        target.style.removeProperty("padding-top");
        target.style.removeProperty("padding-bottom");
        target.style.removeProperty("margin-top");
        target.style.removeProperty("margin-bottom");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
        //alert("!");
    }, duration);
};
let slideDown = (target, duration = 500) => {
    target.style.removeProperty("display");
    let display = window.getComputedStyle(target).display;

    if (display === "none") display = "flex";

    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = "border-box";
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + "ms";
    target.style.height = height + "px";
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");
    window.setTimeout(() => {
        target.style.removeProperty("height");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
    }, duration);
};
let slideToggle = (target, duration = 500) => {
    if (window.getComputedStyle(target).display === "none") {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
};

function toggle_attr_expand(element) {

    if (element.getAttribute("aria-expanded") === "true") {
        element.setAttribute("aria-expanded", "false");
    } else {
        element.setAttribute("aria-expanded", "true");
    }
}

/*————————————————————————————————————————————————————*\
    ●❱ Sub menu
\*————————————————————————————————————————————————————*/
const submenu = document.querySelectorAll('#submenu');
const hambuger = document.querySelector('.hamburger');
let display_hamburger = '';

if (hambuger) {
    window.addEventListener("resize", function(){
        display_hamburger = window.getComputedStyle(hambuger, null).display;
    });
}

if(submenu) {
    submenu.forEach((e, i) => {
        submenu[i].addEventListener('click', () => {
            slideToggle(submenu[i].nextElementSibling);
            toggle_attr_expand(submenu[i]);
            if (display_hamburger != 'flex') {
                submenu.forEach((e, j) => {
                    if (i != j){
                        submenu[j].nextElementSibling.style.display="none";
                    }
                });
            }
            submenu[i].lastElementChild.classList.toggle('active');
        });
    });
}

const menu_vertical = document.querySelector('#checkbox');
const menu_list = document.querySelector('.menu__list');
if (menu_vertical || menu_list) {
    menu_vertical.addEventListener('change', () => {
        if (menu_vertical.checked)
            menu_list.classList.add('menu_active');
        else
            menu_list.classList.remove('menu_active');
    });
}


/*————————————————————————————————————————————————————*\
    ●❱ Filters
\*————————————————————————————————————————————————————*/
const btn_filters = document.querySelectorAll('#btn_group_filter');
if (btn_filters) {
    btn_filters.forEach((e, i) => {
        btn_filters[i].addEventListener('click', () => {
            slideToggle(btn_filters[i].nextElementSibling);
            toggle_attr_expand(btn_filters[i]);
            btn_filters[i].lastElementChild.classList.toggle('active');
        });
    });
}

const btn_subfilters = document.querySelectorAll('#btn_subgroup_filter');
if (btn_subfilters || btn_filters) {
    btn_subfilters.forEach((e, i) => {
        btn_subfilters[i].addEventListener('click', () => {
            if (btn_subfilters[i].nextElementSibling || btn_filters[i].lastElementChild) {
                slideToggle(btn_subfilters[i].nextElementSibling);
                toggle_attr_expand(btn_subfilters[i]);
                btn_subfilters[i].lastElementChild.classList.toggle('active');
            }
        });
    });
}

